import React, { useState } from 'react';
import Chatbot from './Chatbot';
import GeneratedPage from './GeneratedPage';
import './App.css';

const App = () => {
    const [pageContent, setPageContent] = useState('');

    return (
        <div className="App">
            <Chatbot setPageContent={setPageContent} />
            {pageContent && <GeneratedPage content={pageContent} />}
        </div>
    );
};

export default App;
